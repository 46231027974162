import React, { Fragment } from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { Grid, MenuItem, Menu } from '@material-ui/core';
import { ALL_LABEL } from 'utils/constants';
import CommonPageLayout from '../../common/Page';
import { SelectField, AutoCompleteField } from '../../common/InputFields';
import {
  BUS_SETTINGS,
  BUS_DETAIL,
  BUS_ADD,
  EDIT_BUS,
  CENTRE_SETTINGS_MANAGEMENT,
} from '../../../routes/constants';
import Button from '../../common/Button';
import CommonTable from '../../common/Table';
import Pagination from '../../common/Pagination';
import { replaceUrlParam } from '../../../utils';

const getYearOptions = () => {
  const yearOptions = [];
  const currentYear = parseInt(moment().format('YYYY'), 10);
  for (let i = currentYear - 5; i < currentYear; i++) {
    yearOptions.push({ label: i, description: i });
  }
  for (let i = currentYear; i <= currentYear + 5; i++) {
    yearOptions.push({ label: i, description: i });
  }
  return yearOptions;
};

const BusListView = ({
  canWrite,
  centreOptions,
  year,
  tableData,
  totalCount,
  perPage,
  page,
  handlePagination,
  handleFilterChange,
  status,
  direction,
  onRowClick,
  anchorEl,
  parent,
  history,
  selectedBusEntryID,
  snackBarMessage,
  hideSnackBarMessage,
  setDeletePopUpOpen,
  t,
  handleAutoCompleteChange,
}) => {
  const crumbValues = [
    {
      label: t('common.centreManagementLabel'),
      link: CENTRE_SETTINGS_MANAGEMENT,
    },
    {
      label: t('bus_management.busManagementLabel'),
      link: BUS_SETTINGS,
    },
  ];
  const headLabels = [
    {
      title: t('bus_management.busNameLabel'),
    },
    {
      title: t('common.centre'),
    },
    {
      title: t('bus_management.busPlateNoLabel'),
    },
    {
      title: t('bus_management.directionTypeLabel'),
    },
    {
      title: t('common.time'),
    },
    {
      title: t('common.year'),
    },
    {
      title: t('common.status'),
    },
    {
      title: t('bus_management.busCompanyLabel'),
    },
    {
      title: '',
    },
  ];
  const directionTypes = [
    {
      label: 'departure',
      description: t('bus_management.directionTypeDeparture'),
    },

    {
      label: 'arrival',
      description: t('bus_management.directionTypeArrival'),
    },
  ];
  const statusOptions = [
    { description: t('common.active'), label: 'active' },
    { description: t('common.disabled'), label: 'inactive' },
  ];

  return (
    <CommonPageLayout
      title={t('bus_management.busManagementLabel')}
      breadcrumbList={crumbValues}
    >
      <Fragment>
        <Grid container justify="flex-end">
          {canWrite && (
            <Grid item>
              <Button link={BUS_ADD}>{t('bus_management.addBusButton')}</Button>
            </Grid>
          )}
        </Grid>
        <Grid container spacing="5" className="w3-padding-16">
          <Grid item xs={3} md={3}>
            <AutoCompleteField
              name="centre"
              label={t('common.centre')}
              onChange={handleAutoCompleteChange}
              options={centreOptions}
              autoHighlight
              defaultValue={ALL_LABEL}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <SelectField
              label={t('common.status')}
              options={statusOptions}
              emptyMenu
              value={status}
              name="status"
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <SelectField
              label={t('common.year')}
              options={getYearOptions()}
              emptyMenu
              value={year}
              name="year"
              onChange={handleFilterChange}
            />
          </Grid>
          <Grid item xs={3} md={3}>
            <SelectField
              label={t('bus_management.directionTypeLabel')}
              name="direction"
              options={directionTypes}
              emptyMenu
              value={direction}
              onChange={handleFilterChange}
            />
          </Grid>
        </Grid>
        <Grid container>
          <CommonTable
            headerstyle="s7t-feelist-headerstyle"
            contentstyle="s7t-feelist-contentstyle"
            noContentText={t('bus_management.noBusesFound')}
            labels={headLabels}
            tableData={tableData}
            onRowClick={onRowClick}
            enableHover
          />
        </Grid>
        <Grid container className="w3-padding-16">
          <Pagination
            onChange={handlePagination}
            perPage={perPage}
            currentPage={page}
            defaultPerPage={perPage}
            data={{
              totalCount,
              totalLabel: t('common.total'),
            }}
          />
        </Grid>
        <Grid container>
          <Menu
            id="simple-popper"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => parent.setState({ anchorEl: null })}
          >
            <MenuItem
              onClick={() => {
                if (get(snackBarMessage.visibility)) {
                  hideSnackBarMessage();
                }
                setDeletePopUpOpen(
                  `${history.location.pathname}${history.location.search}`,
                  true
                );
                history.push({
                  pathname: replaceUrlParam(BUS_DETAIL, {
                    id: selectedBusEntryID,
                  }),
                });
              }}
            >
              {t('common.deleteLabel')}
            </MenuItem>
            <MenuItem
              onClick={() => {
                if (get(snackBarMessage.visibility)) {
                  hideSnackBarMessage();
                }
                history.push({
                  pathname: replaceUrlParam(EDIT_BUS, {
                    id: selectedBusEntryID,
                  }),
                });
              }}
            >
              {t('common.edit')}
            </MenuItem>
          </Menu>
        </Grid>
      </Fragment>
    </CommonPageLayout>
  );
};

export default BusListView;
