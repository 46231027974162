import { get, camelCase, isArray } from 'lodash';
import gql from 'graphql-tag';
import { msGetEventTypes } from 'components/views/EventManagement/EventManagementHelpers';
import DatadogHandler from 'utils/datadog';
import SkRequest from './BaseRequest';
import { QueryBuilder } from '../queryBuilder';
import {
  FETCH_ALL_OCCUPATIONS,
  FETCH_CATEGORY,
  FETCH_LEVEL,
  FETCH_SCHOOL_CONFIG,
  FETCH_ALL_TAGS,
  FETCH_ALL_CENTRES,
  FETCH_ALL_SERVICE_LEVELS,
  FETCH_ALL_CENTRE_LEVELS,
  FETCH_APP_CONFIG,
  FETCH_ECDA_COMBINED_WORKING_STATUS,
  FETCH_ALL_REGISTRATION_CONFIG,
  FETCH_SUGGEST_LEVELS,
  FETCH_SUGGEST_LEVELS_ERROR,
  FETCH_SUGGEST_LEVELS_SUCCESS,
  FETCH_ALL_HHI_OPTIONS,
} from './actionTypes';
import { findEnrollmentCentres } from './ParentEnquiry';
import {
  FETCH_ALL_LEVELS,
  FETCH_CENTRE_LEVELS,
  FETCH_CONFIG_BY_CATEGORIES,
  FETCH_REGISTRATION_CONFIG,
  FIND_ALL_CONFIG_CATEGORY,
  GET_ECDA_COMBINED_WORKING_STATUS,
  SUGGEST_LEVELS,
  HOUSEHOLD_INCOME_RANGE_OPTIONS,
} from '../models/configModels';
import { CONFIG_FRAGMENT } from '../models/commonFragments';
import apolloUtils, { SkApolloRequest } from '../../utils/apolloUtils';
import { DEFAULT_ERROR, FEATURE_FLAGS } from '../../utils/constants';
import {
  actionHandlerWithFeatureFlag,
  checkMSEnabled,
  parseGraphQLError,
} from '../../utils';
import {
  schoolService,
  BROADCAST_ENDPOINT,
} from '../../utils/msEndpointConstants';

const _mQueryBuilder = new QueryBuilder();

_mQueryBuilder.setNewQuery('schoolConfigByFkSchoolKey', {
  value: 'boolean',
});

_mQueryBuilder.setNewQuery('findAllConfigByCategory', {
  totalCount: 'number',
  data: {
    ID: 'number',
    fkSchool: 'number',
    label: 'string',
    description: 'string',
    active: 'bool',
    createdAt: 'timestamp',
    updatedAt: 'timestamp',
  },
});

_mQueryBuilder.setNewQuery('suggestLevel', {
  ID: 'number',
  fkSchool: 'number',
  label: 'string',
  fromMonth: 'number',
  toMonth: 'number',
  active: 'bool',
  createdAt: 'timestamp',
  updatedAt: 'timestamp',
  programs: {
    data: {
      ID: 'number',
      label: 'string',
      from: 'timestamp',
      to: 'timestamp',
    },
  },
});

_mQueryBuilder.setNewQuery('findAllSchoolConfig', {
  totalCount: 'number',
  data: {
    ID: 'number',
    fkSchool: 'number',
    key: 'string',
    value: 'string',
    active: 'bool',
    createdAt: 'timestamp',
    updatedAt: 'timestamp',
  },
});

_mQueryBuilder.setNewQuery('findAllTags', {
  data: {
    ID: 'number',
    description: 'string',
  },
});

_mQueryBuilder.setNewQuery('findAllCentreForSchool', {
  data: {
    ID: 'number',
    label: 'string',
    addresses: {
      data: {
        lat: 'float',
        lng: 'float',
      },
    },
  },
});

_mQueryBuilder.setNewQuery('codesByLabelFkSchool', {
  data: {
    ID: 'number',
    label: 'string',
  },
});

const dispatchOccupationsFetch = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_OCCUPATIONS,
    key: 'allOccupations',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchCategoryFetch = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_CATEGORY,
    key: 'fetchCategory',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchAppConfig = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_APP_CONFIG,
    key: 'appConfig',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchGetECDACombinedWorkingStatus = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ECDA_COMBINED_WORKING_STATUS,
    key: 'ecdaCombinedWorkingStatus',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchRegistrationConfig = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_REGISTRATION_CONFIG,
    key: 'registrationConfig',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchLevelsConfig = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_LEVEL,
    key: 'levelsConfig',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchSchoolConfig = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_SCHOOL_CONFIG,
    key: 'schoolConfig',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchTags = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_TAGS,
    key: 'tags',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchCentres = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_CENTRES,
    key: 'allCetres',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchFetchServiceLevels = (dispatch, data = {}) => {
  dispatch({
    type: FETCH_ALL_SERVICE_LEVELS,
    key: 'allServiceLevels',
    value: data,
  });
};

const dispatchFetchCentreLevels = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_CENTRE_LEVELS,
    key: 'allServiceLevels',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

const dispatchAllHHIIncomeLevelOptions = (
  dispatch,
  inProgress = true,
  data = {},
  error = null
) => {
  dispatch({
    type: FETCH_ALL_HHI_OPTIONS,
    key: 'householdIncomeList',
    value: {
      inProgress,
      data,
      error,
    },
  });
};

export const dispatchSetSchoolConfig = data => async dispatch => {
  dispatch({
    type: FETCH_SCHOOL_CONFIG,
    key: 'schoolConfig',
    value: {
      inProgress: false,
      data: {
        key: 'list',
        data,
      },
      error: null,
    },
  });
};

export const getAllOccupationsForGroups =
  (groupsArr, fkSchool) => async dispatch => {
    dispatchOccupationsFetch(dispatch);

    const findAllConfigByCategory = [];
    groupsArr.forEach(eachGroup => {
      findAllConfigByCategory.push({
        searchCategory: eachGroup,
        filter: {
          fkSchool,
        },
      });
    });

    const query = _mQueryBuilder.getBulkQueryAsString({
      findAllConfigByCategory,
    });

    const nextQuery = _mQueryBuilder.getQueryAsString(
      'findAllConfigByCategory',
      {
        searchCategory: 'Occupation',
        filter: {
          fkSchool,
        },
      }
    );

    try {
      const data = await SkRequest({
        data: {
          query,
        },
        method: 'POST',
      });

      const salaryEmployeeData = await SkRequest({
        data: {
          query: nextQuery,
        },
        method: 'POST',
      });

      if (data.success && salaryEmployeeData.success) {
        const occupationList = {
          selfEmployed: data.data,
          salariedEmployee: salaryEmployeeData.data,
        };

        dispatchOccupationsFetch(dispatch, false, { data: occupationList });
      } else {
        dispatchOccupationsFetch(dispatch, false, null, data.error);
      }
      return data;
    } catch (ex) {
      dispatchOccupationsFetch(dispatch, false, null, { error: ex.message });
    }
  };

export const monoGetConfigByCategory = async (
  _,
  { searchCategory, filter = {}, pagination = {}, dispatch }
) => {
  dispatchCategoryFetch(dispatch, true, { data: null, type: searchCategory });
  const reqData = {
    searchCategory,
    filter,
    pagination,
  };

  try {
    const data = await SkApolloRequest({
      params: {
        query: FIND_ALL_CONFIG_CATEGORY,
        variables: reqData,
      },
      type: 'query',
    });
    if (data.success) {
      if (searchCategory === 'cancel_registration_reason') {
        let reasonArray = null;
        reasonArray = get(data, 'data.findAllConfigByCategory.data');
        if (reasonArray?.length > 0) {
          const otherIndex = reasonArray.findIndex(
            item => item.label === 'others'
          );
          reasonArray.push(reasonArray.splice(otherIndex, 1)[0]);
          data.data.findAllConfigByCategory.data = reasonArray;
        }
      }
      if (searchCategory === 'workplace_staff') {
        let workplaceArr = null;
        workplaceArr = get(data, 'data.findAllConfigByCategory.data');
        data.data.findAllConfigByCategory.fullData = [...workplaceArr];
        if (workplaceArr?.length > 0) {
          workplaceArr = workplaceArr.filter(item => item.display);
          data.data.findAllConfigByCategory.data = workplaceArr;
        }
      }

      dispatchCategoryFetch(dispatch, false, {
        data: data.data,
        type: searchCategory,
      });
    } else {
      dispatchCategoryFetch(
        dispatch,
        false,
        { data: null, type: searchCategory },
        data.error
      );
    }
    return data;
  } catch (ex) {
    dispatchCategoryFetch(
      dispatch,
      false,
      { data: null, type: searchCategory },
      { error: ex.message }
    );
  }
};

export const getConfigByCategory =
  (searchCategory, filter = {}, pagination = {}) =>
  async dispatch => {
    let msHandler = monoGetConfigByCategory;
    switch (searchCategory) {
      case 'event_types':
        msHandler = msGetEventTypes;
        break;
      default:
        break;
    }
    const data = await actionHandlerWithFeatureFlag(
      FEATURE_FLAGS.MS_PHASE_2D_EVENT_RESOURCES_VISITOR_BUS_PORTAL_DISABLED,
      {
        msHandler,
        monoHandler: monoGetConfigByCategory,
        extraData: {
          searchCategory,
          filter,
          pagination,
          dispatchCategoryFetch,
          dispatch,
        },
      }
    );
    return data;
  };

export const getECDACombinedWorkingStatus = fkSchool => async dispatch => {
  dispatchGetECDACombinedWorkingStatus(dispatch);

  const client = await apolloUtils();

  try {
    const result = await client.query({
      query: GET_ECDA_COMBINED_WORKING_STATUS,
      variables: {
        schoolID: fkSchool,
      },
    });

    if (!result.loading && result.data && !result.data.error) {
      dispatchGetECDACombinedWorkingStatus(dispatch, false, result.data);
    } else {
      dispatchGetECDACombinedWorkingStatus(dispatch, false, result.data.error);
    }
    return result.data;
  } catch (ex) {
    dispatchGetECDACombinedWorkingStatus(dispatch, false, {
      error: ex.message,
    });
  }
};

export const getAppConfig = fkSchool => async dispatch => {
  dispatchFetchAppConfig(dispatch);

  const client = await apolloUtils();

  try {
    const result = await client.query({
      query: FETCH_CONFIG_BY_CATEGORIES,
      variables: {
        filter: {
          fkSchool,
        },
      },
    });

    if (!result.loading && result.data && !result.data.error) {
      const workplaceStaffConfig = get(
        result,
        'data.workPlaceStaffConfig.data'
      );
      if (isArray(workplaceStaffConfig)) {
        result.data.workPlaceStaffConfig.fullData = [...workplaceStaffConfig];
        const otherIndex = workplaceStaffConfig.findIndex(
          item => item.label.toLowerCase() === 'not_a_staff'
        );
        workplaceStaffConfig.push(
          workplaceStaffConfig.splice(otherIndex, 1)[0]
        );
        result.data.workPlaceStaffConfig.data = workplaceStaffConfig.filter(
          item => item.display
        );
      }

      const medicalConditionConfigs = get(
        result,
        'data.medicalConditionConfig.data'
      );
      if (isArray(medicalConditionConfigs)) {
        const mccs = medicalConditionConfigs.map(mcc => {
          if (mcc.description === 'others') {
            return {
              ...mcc,
              description: 'Others',
            };
          }
          return mcc;
        });
        result.data.medicalConditionConfig.data = mccs;
      }

      let cancellationReasons = null;

      cancellationReasons = get(
        result,
        'data.preEnrolmentWithdrawalReasons.data',
        []
      );

      if (cancellationReasons?.length > 0) {
        const otherIndex = cancellationReasons.findIndex(
          item => item.label.toLowerCase() === 'others'
        );
        cancellationReasons.push(cancellationReasons.splice(otherIndex, 1)[0]);
        result.data.preEnrolmentWithdrawalReasons.data = cancellationReasons;
      }

      const allGroups = result.data.selfEmployedOccupationGroupConfig.data;
      let selfEmployedOccupationQuery = '';

      allGroups.forEach(group => {
        selfEmployedOccupationQuery += `
                    ${camelCase(
                      group.label
                    )}: findAllConfigByCategory(searchCategory: "${
          group.label
        }", filter: $filter) {
                        totalCount
                        data {
                            ...Config
                        }
                    }
                `;
      });

      const query = gql`
                query ($filter: CodeFilter) {
                    ${selfEmployedOccupationQuery}
                }
                ${CONFIG_FRAGMENT}
            `;

      const occupationResponse = await client.query({
        query,
        variables: {
          filter: {
            fkSchool,
          },
        },
      });

      if (!occupationResponse.data.error) {
        result.data.selfEmployedConfig = occupationResponse;
        dispatchFetchAppConfig(dispatch, false, result.data);
      } else {
        dispatchFetchAppConfig(dispatch, false, occupationResponse.data.error);
      }
    } else {
      dispatchFetchAppConfig(dispatch, false, result.data.error);
    }

    return result.data;
  } catch (ex) {
    dispatchFetchAppConfig(dispatch, false, { error: ex.message });
  }
};

export const clearLevelsConfig = () => async dispatch => {
  dispatchLevelsConfig(dispatch, false, null, null);
};

export const getLevelsConfig =
  (reqData, fetchCentres = false) =>
  async dispatch => {
    dispatchLevelsConfig(dispatch);
    const levelReqData = {
      IDSchool: reqData.IDSchool,
      dateOfBirth: reqData.dateOfBirth,
      enrollmentDate: reqData.enrollmentDate,
    };
    const query = _mQueryBuilder.getQueryAsString('suggestLevel', levelReqData);

    try {
      const data = await SkRequest({
        data: {
          query,
        },
        method: 'POST',
      });

      if (data.success) {
        dispatchLevelsConfig(dispatch, false, { data: data.data });
        if (fetchCentres) {
          const levelID = data.data.suggestLevel.ID;
          const newRequest = {
            IDSchool: reqData.IDSchool,
            enrollmentDate: reqData.enrollmentDate,
            level: levelID,
          };
          if (reqData.workplaceID && reqData.workplaceID !== '') {
            newRequest.workplaceID = reqData.workplaceID;
          }
          if (reqData.childNationality) {
            newRequest.nationality = reqData.childNationality;
          }

          if (reqData.program) {
            newRequest.program = reqData.program;
          }

          if (!reqData.hasRadiusCentreSearch) {
            dispatch(findEnrollmentCentres(newRequest));
          }
        }
      } else {
        dispatchLevelsConfig(dispatch, false, null, data.error);
      }
      return data;
    } catch (ex) {
      dispatchLevelsConfig(dispatch, false, null, { error: ex.message });
    }
  };

export const getSchoolOtpValidate = (fkSchool, key) => async () => {
  const query = _mQueryBuilder.getQueryAsString('schoolConfigByFkSchoolKey', {
    fkSchool,
    key,
  });

  try {
    const data = await SkRequest({
      data: {
        query,
      },
      method: 'POST',
    });
    return data;
  } catch (ex) {
    return ex;
  }
};

export const getSchoolConfig =
  (filter = {}) =>
  async dispatch => {
    const key = filter.key ? filter.key : 'list';
    dispatchSchoolConfig(dispatch, false, { key });
    const query = _mQueryBuilder.getQueryAsString('findAllSchoolConfig', {
      filter,
    });

    try {
      const data = await SkRequest({
        data: {
          query,
        },
        method: 'POST',
      });

      if (data.success) {
        dispatchSchoolConfig(dispatch, false, { data: data.data, key });
      } else {
        dispatchSchoolConfig(dispatch, false, { key }, data.error);
      }
    } catch (ex) {
      dispatchSchoolConfig(dispatch, false, { key }, { error: ex.message });
    }
  };

export const fetchAllTags = IDSchool => async dispatch => {
  dispatchFetchTags(dispatch);
  try {
    const data = await SkRequest({
      data: {
        query: _mQueryBuilder.getQueryAsString('findAllTags', {
          IDSchool,
        }),
      },
      method: 'POST',
    });

    if (data.success) {
      dispatchFetchTags(dispatch, false, {
        data: data.data,
        key: 'tags',
      });
    } else {
      dispatchFetchTags(dispatch, false, null, data.error);
    }

    return data;
  } catch (ex) {
    dispatchFetchTags(dispatch, false, null, { error: ex.message });
    return ex;
  }
};

export const fetchAllCentres =
  (IDSchool, filter = {}) =>
  async dispatch => {
    dispatchFetchCentres(dispatch);
    try {
      const data = await SkRequest({
        data: {
          query: _mQueryBuilder.getQueryAsString('findAllCentreForSchool', {
            IDSchool: parseInt(IDSchool, 10),
            includeClosedCentres: true,
            filter,
          }),
        },
        method: 'POST',
      });

      if (data.success) {
        dispatchFetchCentres(dispatch, false, { data: data.data });
      } else {
        dispatchFetchCentres(dispatch, false, null, data.error);
      }

      return data;
    } catch (ex) {
      dispatchFetchCentres(dispatch, false, null, { error: ex.message });
      return ex;
    }
  };

export const fetchAllServiceLevels = filter => async (dispatch, getState) => {
  const isMSEnabled = checkMSEnabled(getState());
  const reqData = {
    filter,
    pagination: { sort: ['fromMonth'] },
  };

  if (isMSEnabled && get(filter, 'isBroadCast', false)) {
    try {
      const _schoolService = schoolService();
      const schoolId = get(filter, 'fkSchool');
      const response = await _schoolService.get(
        BROADCAST_ENDPOINT.GET_MS_LEVEL(schoolId)
      );

      const transformData = get(response, 'data.data', []).map(eachResp => ({
        ID: get(eachResp, 'id'),
        label: get(eachResp, 'label'),
        code: get(eachResp, 'code'),
      }));

      const msResponseData = {
        success: true,
        data: {
          findAllLevelsConfig: {
            data: transformData,
          },
        },
      };

      dispatchFetchServiceLevels(dispatch, msResponseData);
      return msResponseData;
    } catch (ex) {
      DatadogHandler.addError(ex);
      DatadogHandler.sendLog(ex, {}, 'error');
      return;
    }
  }
  const client = await apolloUtils();
  const response = await client.query({
    query: FETCH_ALL_LEVELS,
    variables: reqData,
  });

  dispatchFetchServiceLevels(dispatch, response);
  return response;
};

export const fetchAllCentreLevels = reqData => async dispatch => {
  dispatchFetchCentreLevels(dispatch);
  try {
    const response = await SkApolloRequest({
      params: {
        query: FETCH_CENTRE_LEVELS,
        variables: reqData,
      },
      type: 'query',
    });

    if (response.data) {
      dispatchFetchCentreLevels(dispatch, false, response.data, null);
    } else {
      dispatchFetchCentreLevels(
        dispatch,
        false,
        null,
        get(response, 'errors[0].message') || DEFAULT_ERROR
      );
    }
    return response;
  } catch (err) {
    dispatchFetchCentreLevels(
      dispatch,
      false,
      null,
      err.message.replace(/GraphQL error./, '')
    );
    throw err;
  }
};

export const getRegistrationConfig = fkSchool => async dispatch => {
  dispatchFetchRegistrationConfig(dispatch);

  const client = await apolloUtils();

  try {
    const result = await client.query({
      query: FETCH_REGISTRATION_CONFIG,
      variables: {
        filter: {
          fkSchool,
        },
      },
    });

    if (!result.loading && result.data && !result.error) {
      dispatchFetchRegistrationConfig(dispatch, false, result.data);
    } else {
      dispatchFetchRegistrationConfig(dispatch, false, result.error);
    }

    return result.data;
  } catch (ex) {
    dispatchFetchRegistrationConfig(dispatch, false, { error: ex.message });
  }
};

export const suggestLevels = params => async dispatch => {
  dispatch({
    type: FETCH_SUGGEST_LEVELS,
    value: {
      error: null,
      data: [],
      inProgress: true,
    },
  });

  try {
    const res = await SkApolloRequest({
      params: {
        query: SUGGEST_LEVELS,
        variables: {
          IDSchool: params.IDSchool,
          IDCentre: params.IDCentre,
          dateOfBirth: params.dateOfBirth,
          enrollmentDate: params.enrollmentDate,
        },
      },
      type: 'query',
    });
    if (res.success) {
      dispatch({
        type: FETCH_SUGGEST_LEVELS_SUCCESS,
        value: {
          data: get(res, 'data.suggestLevels') || [],
          inProgress: false,
        },
      });
    } else {
      dispatch({
        type: FETCH_SUGGEST_LEVELS_ERROR,
        value: {
          error: get(res, 'error.0.message'),
        },
      });
    }
  } catch (ex) {
    dispatch({
      type: FETCH_SUGGEST_LEVELS_ERROR,
      value: { error: get(ex, 'message'), inProgress: false },
    });
  }
};

export const getHHIIncomeLevelOptions = schoolID => async dispatch => {
  dispatchAllHHIIncomeLevelOptions(dispatch);

  try {
    const result = await SkApolloRequest({
      params: {
        query: HOUSEHOLD_INCOME_RANGE_OPTIONS,
        variables: { schoolID },
      },
      type: 'query',
    });

    if (result.success) {
      dispatchAllHHIIncomeLevelOptions(dispatch, false, result.data);
    } else {
      dispatchAllHHIIncomeLevelOptions(dispatch, false, null, result.error);
    }
  } catch (ex) {
    dispatchAllHHIIncomeLevelOptions(dispatch, false, null, {
      error: parseGraphQLError(ex),
    });
  }
};

export default fetchAllServiceLevels;
