import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { isEmpty, get } from 'lodash';
import { withTranslation, Trans } from 'react-i18next';
import {
  createBusRoute,
  fetchAccessibleCentreForModule,
  updateBusRoute,
  showSnackBarMessage,
} from '../../../../redux/actions';
import {
  isMomentValid,
  mobileGoogleValidation,
  processQueryResult,
} from '../../../../utils';
import { DEFAULT_COUNTRY_CODE } from '../../../../utils/constants';
import { BUS_SETTINGS } from '../../../../routes/constants';
import BusFormView from './BusFormView';

const addNewBusRoute = async (
  createNewBusRoute,
  reqData,
  history,
  setSubmitting,
  setSubmitError,
  showSnackBarMessageMethod
) => {
  let errorMessage = null;
  try {
    const result = await createNewBusRoute(reqData);
    const successCallback = () => {
      history.push(BUS_SETTINGS);
      showSnackBarMessageMethod(
        <Trans i18nKey="bus_management.busRouteAddSuccess" />
      );
      setSubmitting(false);
    };

    errorMessage = processQueryResult(result, successCallback);
  } catch (error) {
    errorMessage = error.message.replace('GraphQL error:', '');
  }

  if (errorMessage && errorMessage !== '') {
    setSubmitError(errorMessage);
    setSubmitting(false);
  }
};

const editBusRoute = async (
  editBusDetails,
  reqData,
  history,
  setSubmitting,
  setSubmitError,
  showSnackBarMessageMethod,
  t
) => {
  let errorMessage = null;
  try {
    const result = await editBusDetails(reqData);
    const successCallback = () => {
      const busEditedText = t('bus_management.busEntryEditedText');
      history.push(BUS_SETTINGS);
      showSnackBarMessageMethod(busEditedText);
      setSubmitting(false);
    };

    errorMessage = processQueryResult(result, successCallback);
  } catch (error) {
    errorMessage = error.message.replace('GraphQL error:', '');
  }

  if (errorMessage && errorMessage !== '') {
    setSubmitError(errorMessage);
    setSubmitting(false);
  }
};

const initialiseBusDetail = (busDetail, isEdit) => {
  const formFields = {
    busName: get(busDetail, 'busName', ''),
    busCompany: get(busDetail, 'busCompany', ''),
    driverName: get(busDetail, 'driverName', ''),
    driverContactNo: get(busDetail, 'driverContactNo', ''),
    driverContactNoCountryCode:
      get(busDetail, 'driverContactNoCountryCode', DEFAULT_COUNTRY_CODE) ||
      DEFAULT_COUNTRY_CODE,
    busPlateNo: get(busDetail, 'busPlateNo', ''),
    attendantName: get(busDetail, 'attendantName', ''),
    attendantContactNo: get(busDetail, 'attendantContactNo', ''),
    attendantContactNoCountryCode:
      get(busDetail, 'attendantContactNoCountryCode', DEFAULT_COUNTRY_CODE) ||
      DEFAULT_COUNTRY_CODE,
    directionType: get(busDetail, 'directionType', ''),
    year: isMomentValid(get(busDetail, 'year'))
      ? get(busDetail, 'year', '')
      : moment(),
    departureTime: get(busDetail, 'departureTime')
      ? moment(busDetail.departureTime)
      : null,
    associatedCentre: get(busDetail, 'associatedCentre', ''),
    attendantPassCode: get(busDetail, 'attendantPassCode', ''),
    status: get(busDetail, 'status', '1'),
    isEdit,
  };

  return formFields;
};

const BusForm = ({
  countryCodes,
  fetchCentres,
  createNewBusRoute,
  editBusDetails,
  fkSchool,
  centres,
  history,
  t,
  busInfo,
  isEdit = false,
  handleCancel = () => {},
  showSnackBarMessageMethod,
}) => {
  const [submitError, setSubmitError] = useState(null);
  const [showOrHidePopUp, setShowOrHidePopUp] = useState(false);
  const [busDetail, setBusDetail] = useState(
    initialiseBusDetail(busInfo, isEdit)
  );

  useEffect(() => {
    if (get(centres, 'inProgress') || !get(centres, 'data')) {
      fetchCentres('bus_user');
    }
  }, []);

  useEffect(() => {
    setBusDetail(initialiseBusDetail(busInfo, isEdit));
  }, [busInfo]);

  const togglePopUpState = showOrHide => setShowOrHidePopUp(showOrHide);

  const requiredErrorMsg = t('common.requiredError');
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: busDetail,
    validationSchema: Yup.lazy(values =>
      Yup.object().shape({
        busName: Yup.string().required(requiredErrorMsg),
        busCompany: Yup.string().required(requiredErrorMsg),
        driverName: Yup.string().required(requiredErrorMsg),
        driverContactNoCountryCode: Yup.string()
          .nullable()
          .required(requiredErrorMsg),
        driverContactNo: Yup.string()
          .required(requiredErrorMsg)
          .test('mobileValidation', t('common.mobileInvalid'), async value => {
            const countryName = get(
              countryCodes.find(
                key => key.value === values.driverContactNoCountryCode
              ),
              'country',
              'SG'
            );
            const validation = mobileGoogleValidation(value, countryName);
            return validation.valid;
          }),
        busPlateNo: Yup.string().required(requiredErrorMsg),
        attendantName: Yup.string().required(requiredErrorMsg),
        attendantContactNoCountryCode: Yup.string()
          .nullable()
          .required(requiredErrorMsg),
        attendantContactNo: Yup.string()
          .required(requiredErrorMsg)
          .test('mobileValidation', t('common.mobileInvalid'), async value => {
            const countryName = get(
              countryCodes.find(
                key => key.value === values.attendantContactNoCountryCode
              ),
              'country',
              'SG'
            );
            const validation = mobileGoogleValidation(value, countryName);
            return validation.valid;
          }),
        year: Yup.mixed().test('dateValue', t('common.invalidDate'), value => {
          if (!value) {
            return true;
          }
          return value && isMomentValid(moment(value));
        }),
        directionType: Yup.string().required(requiredErrorMsg),
        departureTime: Yup.mixed().test(
          'departureTimeValue',
          t('error.invalidTimeFormatError'),
          value => {
            if (!value) {
              return false;
            }
            return value && isMomentValid(moment(value));
          }
        ),
        associatedCentre: Yup.number().required(requiredErrorMsg),
        isEdit: Yup.boolean(),
        status: Yup.string().when('isEdit', {
          is: true,
          then: Yup.string().required(requiredErrorMsg),
        }),
      })
    ),
    onSubmit: () => togglePopUpState(true),
  });

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setSubmitting,
  } = formik;

  const year = isMomentValid(get(values, 'year'))
    ? values.year.clone()
    : moment();
  const reqData = {
    fkSchool,
    fkCentre: get(values, 'associatedCentre'),
    Driver: {
      firstname: get(values, 'driverName'), // This is full name concat from firstname and lastname
      lastname: '', // This set empty to avoid duplicate with lastname incase lastname is concat with firstname
      mobilePhone: get(values, 'driverContactNo').toString(),
      mobilePhoneCountryCode: get(values, 'driverContactNoCountryCode'),
    },
    Attendant: {
      firstname: get(values, 'attendantName'), // This is full name concat from firstname and lastname
      lastname: '', // This set empty to avoid duplicate with lastname incase lastname is concat with firstname
      mobilePhone: get(values, 'attendantContactNo').toString(),
      mobilePhoneCountryCode: get(values, 'attendantContactNoCountryCode'),
    },
    Bus: {
      plateNumber: get(values, 'busPlateNo'),
    },
    BusEntry: {
      label: get(values, 'busName'),
      busCompany: get(values, 'busCompany'),
      status: get(values, 'status') === '1',
      direction: get(values, 'directionType'),
      yearTime: `${year.startOf('year').format('YYYY-MM-DD')} ${moment(
        get(values, 'departureTime')
      ).format('HH:mm:ss')}`,
    },
  };
  if (isEdit) {
    reqData.DriverID = get(busInfo, 'driverID');
    reqData.AttendantID = get(busInfo, 'attendantID');
    reqData.BusID = get(busInfo, 'busID');
    reqData.BusEntryID = get(busInfo, 'busEntryID');
  }

  return (
    <BusFormView
      data={{
        ...values,
        errors: {
          ...errors,
        },
        touched: {
          ...touched,
        },
      }}
      saveButtonDisabled={!isEdit && (isEmpty(touched) || !isEmpty(errors))}
      showPopUp={showOrHidePopUp}
      togglePopUpState={togglePopUpState}
      apiError={submitError}
      loading={isSubmitting}
      handleBlur={handleBlur}
      handleChange={handleChange}
      handleSave={handleSubmit}
      handleCancel={handleCancel}
      setFieldValue={setFieldValue}
      centreOptions={
        get(centres, 'data.length')
          ? centres.data.map(centre => ({
              label: centre.ID,
              description: centre.label,
            }))
          : []
      }
      isEdit={isEdit}
      t={t}
      setSubmitting={setSubmitting}
      editBusAction={() =>
        editBusRoute(
          editBusDetails,
          reqData,
          history,
          setSubmitting,
          setSubmitError,
          showSnackBarMessageMethod,
          t
        )
      }
      addNewBusRoute={() =>
        addNewBusRoute(
          createNewBusRoute,
          reqData,
          history,
          setSubmitting,
          setSubmitError,
          showSnackBarMessageMethod
        )
      }
    />
  );
};

const mapStateToProps = state => ({
  countryCodes: get(state, 'countries'),
  centres: get(state, 'accessibleCentres', []),
});

const mapDispatchToProps = {
  createNewBusRoute: createBusRoute,
  fetchCentres: fetchAccessibleCentreForModule,
  editBusDetails: updateBusRoute,
  showSnackBarMessageMethod: showSnackBarMessage,
};

const MemoizedBusForm = React.memo(BusForm);

export default compose(withTranslation())(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MemoizedBusForm))
);
