import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import get from 'lodash/get';
import {
  DATA_DOG_ENV,
  DEFAULT_DD_DEPLOY_ENV,
  DEFAULT_DD_API_URL,
  DEFAULT_DD_SCHOOL_ID,
  DEFAULT_DD_IS_EP,
} from './constants';

const SCHOOL_ID = process.env.REACT_APP_SCHOOL_ID;
const checkList = ['password'];

const maskInfo = (key, value) => {
  let maskedValue = value;
  if (checkList.indexOf(key) > -1) {
    maskedValue = '[REDACTED]';
  }
  return maskedValue;
};

export const isEnvAllowedForDatadog = () => {
  const APP_ENV = process?.env?.REACT_APP_DEPLOY_ENV ?? DEFAULT_DD_DEPLOY_ENV;
  const API_ENDPOINT =
    process?.env?.REACT_APP_REACT_API_URL ?? DEFAULT_DD_API_URL;
  const SCHOOL = process?.env?.REACT_APP_SCHOOL_ID ?? DEFAULT_DD_SCHOOL_ID;
  const isEP =
    process?.env?.REACT_APP_ENRICHMENT_PLATFORM === 'true' || DEFAULT_DD_IS_EP;
  let envName = '';

  if (APP_ENV === 'develop' || APP_ENV === 'development') {
    const match = API_ENDPOINT.match(/https:\/\/([^]+)\.api\.skool\.sg/);
    envName = match ? match[1] : '';
  } else if (APP_ENV === 'staging') {
    envName = 'stg';
  } else {
    if (String(SCHOOL) === '1') {
      envName = isEP ? 'mfs-ep-prod' : 'mfs-prod';
    }
    if (String(SCHOOL) === '2') {
      envName = 'lsh-prod';
    }
  }

  const dDEnv = DATA_DOG_ENV.find(env => env.env === envName);
  return dDEnv ? dDEnv?.value : null;
};

export default class DatadogHandler {
  static init() {
    this.initDatadog();
  }

  static initDatadog() {
    // Check if the current environment is listed among the allowed Datadog environments; else, do not initiate
    const ddEnv = isEnvAllowedForDatadog();
    const VERSION =
      process?.env?.REACT_APP_BITBUCKET_BRANCH ?? DEFAULT_DD_DEPLOY_ENV;
    const DD_CLIENT_TOKEN = process?.env?.REACT_APP_DD_CLIENT_TOKEN;
    const DD_APPLICATION_ID = process?.env?.REACT_APP_DD_APP_ID;

    if (DD_CLIENT_TOKEN && DD_APPLICATION_ID && ddEnv) {
      datadogLogs.init({
        clientToken: DD_CLIENT_TOKEN,
        service: 'sn-frontend',
        site: 'datadoghq.eu',
        forwardErrorsToLogs: true,
        isCollectingError: true,
        silentMultipleInit: true,
        sampleRate: 100,
        trackInteractions: true,
        useCrossSiteSessionCookie: true,
        useSecureSessionCookie: true,
        trackSessionAcrossSubdomains: true,
        trackUserInteractions: true,
        env: ddEnv,
        version: VERSION,
        beforeSend: log => {
          if (
            log.origin === 'console' &&
            log.message.includes('console error:')
          ) {
            return false;
          }
        },
      });

      // Check if it's production environment and URL includes '/pss'
      const shouldInitSessionReplay =
        process.env.REACT_APP_ENVIRONMENT === 'production' &&
        get(window, 'location.pathname', '').includes('/pss');

      datadogRum.init({
        enableExperimentalFeatures: ['feature_flags'],
        applicationId: DD_APPLICATION_ID,
        clientToken: DD_CLIENT_TOKEN,
        service: 'sn-frontend',
        site: 'datadoghq.eu',
        env: ddEnv,
        version: VERSION,
        sampleRate: 100,
        sessionReplaySampleRate: 100,
        trackResources: true,
        trackLongTasks: true,
        trackInteractions: true,
        trackUserInteractions: true,
        beforeSend: (event, context) => {
          if (
            event.type === 'error' &&
            event.error.message.includes('console error:')
          ) {
            return false;
          }
          // collect a RUM resource's response headers
          if (event.type === 'resource' && event.resource.type === 'fetch') {
            event.context = {
              ...event.context,
              responseHeaders: context.response.headers,
            };
          }
          // remove email from view url
          event.view.url = event.view.url.replace(
            /email=[^&]*/,
            'email=REDACTED'
          );
        },
      });
      if (shouldInitSessionReplay) {
        datadogRum.startSessionReplayRecording();
      }
    }
  }

  static setUserContextOnRouteChange(route, userDetails) {
    const extra = {
      userRole: get(userDetails, 'userRoleRelations.data[0].role.label'),
      schoolID: SCHOOL_ID,
    };
    this.setUserContext(userDetails.ID, extra);
  }

  static setUserContext(userId, extra) {
    datadogLogs.setGlobalContext({
      id: userId,
      ...extra,
    });
  }

  static addError(error) {
    datadogRum.addError(error);
  }

  static sendLog(message, context, type) {
    const maskedData = JSON.stringify(context, maskInfo);
    datadogLogs.logger.log(message, JSON.parse(maskedData), type);
  }
}
