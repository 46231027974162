import gql from 'graphql-tag';

export const UPDATE_CHILD_PARTICULAR = gql`
  mutation updateChildParticular(
    $childID: Int!
    $childUpdate: ChildUpdate!
    $programType: Int
    $enrollmentDate: Datetime!
    $levelId: Int
    $bypassExceeding: Boolean
  ) {
    updateChildParticular(
      childID: $childID
      childUpdate: $childUpdate
      programType: $programType
      enrollmentDate: $enrollmentDate
      levelId: $levelId
      bypassExceeding: $bypassExceeding
    ) {
      child {
        ID
      }
      childLevel {
        level {
          ID
        }
      }
    }
  }
`;

export const ADD_UPDATE_SPOUSE = gql`
  mutation addUpdateSpouse(
    $applicant: Applicant!
    $schoolID: Int!
    $childID: Int!
    $isChildApplicant: Boolean!
    $updateParentID: Int
    $updateUserID: Int
  ) {
    addUpdateSpouse(
      applicant: $applicant
      schoolID: $schoolID
      childID: $childID
      isChildApplicant: $isChildApplicant
      updateParentID: $updateParentID
      updateUserID: $updateUserID
    ) {
      ID
    }
  }
`;
export const REMOVE_CHILD_SPOUSE = gql`
  mutation removeChildSpouse($IDSpouse: Int!, $siblings: [Int!]!) {
    removeChildSpouse(IDSpouse: $IDSpouse, siblings: $siblings)
  }
`;

export const UPDATE_GUARDIAN_DETAILS = gql`
  mutation updateGuardian($IDParent: Int!, $parentUpdate: ParentUpdate!) {
    updateParentEmailOrMobile(
      IDParent: $IDParent
      parentUpdate: $parentUpdate
    ) {
      ID
      email
      mobilePhone
      mobilePhoneCountryCode
    }
  }
`;

export const UPDATE_PARENT_NATIONALITY = gql`
  mutation updateParentNationality(
    $IDParent: Int!
    $nationality: Nationality!
    $effectiveFrom: Datetime!
  ) {
    updateParentNationality(
      IDParent: $IDParent
      nationality: $nationality
      effectiveFrom: $effectiveFrom
    ) {
      ID
    }
  }
`;

export const UPDATE_PARENT_MARITIAL_STATUS = gql`
  mutation updateParentMaritalStatus(
    $IDParent: Int!
    $maritalStatus: ParentMaritalStatus!
    $effectiveFrom: Datetime!
  ) {
    updateParentMaritalStatus(
      IDParent: $IDParent
      maritalStatus: $maritalStatus
      effectiveFrom: $effectiveFrom
    ) {
      ID
    }
  }
`;

export const UPDATE_PARENT_WORKING_STATUS = gql`
  mutation updateParentWorkingStatus(
    $IDParent: Int!
    $parentWorkingStatus: ParentWorkingStatus!
    $effectiveFrom: Datetime!
  ) {
    updateParentWorkingStatus(
      IDParent: $IDParent
      parentWorkingStatus: $parentWorkingStatus
      effectiveFrom: $effectiveFrom
    ) {
      ID
    }
  }
`;

export const SWITCH_MAIN_APPLICANT = gql`
  mutation switchSpouseToApplicant(
    $applicantID: Int!
    $spouseID: Int!
    $childID: Int!
  ) {
    switchSpouseToApplicant(
      applicantID: $applicantID
      spouseID: $spouseID
      childID: $childID
    )
  }
`;

export const GET_LIFE_SG_EXCEPTION_LIST_QUERY = gql`
  query getLifeSgExceptionList($schoolID: Int!) {
    lifeSgExceptionList(schoolID: $schoolID) {
      data {
        ID
        label
        description
      }
    }
  }
`;

export const GET_LIFE_SG_EXCEPTION_SELECTION = gql`
  query getLifeSgExceptionSelection(
    $schoolID: Int!
    $IDECDARegistration: Int!
  ) {
    getLifeSgExceptionSelection(
      schoolID: $schoolID
      IDECDARegistration: $IDECDARegistration
    ) {
      data {
        fkCode
        fkEcdaRegistration
        code {
          ID
          label
          description
        }
      }
    }
  }
`;

export const UPSERT_LIFE_SG_EXCEPTION = gql`
  mutation upsertLifeSgException(
    $schoolID: Int!
    $lifeSgExceptionDTO: LifeSgExceptionDTO!
  ) {
    upsertLifeSgException(
      schoolID: $schoolID
      lifeSgExceptionDTO: $lifeSgExceptionDTO
    )
  }
`;
